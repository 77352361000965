import { createRouter, createWebHashHistory } from 'vue-router'
import { iRouter } from './router'
import { storeToken } from "@/utils/utils"

let routes = iRouter;

// 路由数据处理
routes.forEach(ele => {
  if (!ele.meta) {
    // 默认值
    ele.meta = {
      alone: true,
      isKeep: true,
      name: ele.label,
    }
  } else {
    ele.meta.name = ele.label;
    let objArr = Object.keys(ele.meta);
    if (!objArr.includes("alone")) {
      ele.meta.alone = true;
    } else if (!objArr.includes("isKeep")) {
      ele.meta.isKeep = true;
    }
  }
})

console.warn("打印路由数据", routes);

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  console.log("前置路由守卫", to, from);
  let store = storeToken();
  let alone = to.meta.alone;
  if (!alone || store) {
    if(to.name=='youke') {
      next('/youke');
    } else {
      next();
    }
  } else {
    if(to.name=='youKe') {
      next('/youKe');
    } else {
      next('/login');
    }
  }
});

router.afterEach((to, from) => {
  console.log("后置路由守卫", to, from);
  document.title = to.meta.name || "页面";
});

export default router
