import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import less from 'less'
import './style/index.less'
import Antd from 'ant-design-vue';
// import 'ant-design-vue/dist/antd.css';
import 'ant-design-vue/dist/antd.variable.min.css';
import { ConfigProvider } from 'ant-design-vue';

// import {createPinia} from 'pinia'
// const app = createApp(App)
// app.config.globalProperties.$echarts = echarts;
// app.use(router).use(createPinia()).mount('#app')
// 主题色设置
ConfigProvider.config({
    theme: {
        primaryColor: '#165DFF',
    },
});

createApp(App).use(less).use(Antd).use(store).use(router).mount('#app')
