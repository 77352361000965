<template>
  <div class="ncontaner">
    <a-breadcrumb>
      <a-breadcrumb-item>{{ routerStr[0] }}</a-breadcrumb-item>
      <a-breadcrumb-item v-if="routerStr[1]">{{
        routerStr[1]
      }}</a-breadcrumb-item>
      <a-breadcrumb-item v-if="routerStr[2]">{{
        routerStr[2]
      }}</a-breadcrumb-item>
    </a-breadcrumb>
  </div>
</template>
<script setup>
// import { useRoute } from "vue-router";
import { ref, watch, defineProps } from "vue";
// import { mrouter } from "@/router/mrouter";
// const route = useRoute();
const props = defineProps({
  routerStr: {
    type: Array,
    required: true,
    default: () => [],
  },
});
let routerStr = ref([]);
watch(
  props,
  (to) => {
    // console.log("路由变化....", JSON.parse(JSON.stringify(to)));
    let tos = JSON.parse(JSON.stringify(to));
    if (tos && tos.routerStr) {
      routerStr.value = tos.routerStr;
    }
    // analyticRouting(to);
  },
  { deep: true, immediate: false }
);
// let routerStr = reactive({ data: [] });
// // 解析路由
// let analyticRouting = (res) => {
//   routerStr.data = [];
//   for (let item of mrouter) {
//     if (item.path == res) {
//       // 一级
//       routerStr.data.push(item.name);
//       return;
//     } else if (item.children && item.children.length > 0) {
//       for (let child of item.children) {
//         if (child.path == res) {
//           // 二级
//           routerStr.data.push(item.name);
//           routerStr.data.push(child.name);
//           return;
//         } else {
//           if (child.children && child.children.length > 0) {
//             for (let ele of child.children) {
//               if (ele.path == res) {
//                 // 三级
//                 routerStr.data.push(item.name);
//                 routerStr.data.push(child.name);
//                 routerStr.data.push(ele.name);
//                 return;
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// };
</script>
<style lang="less" scoped>
.ncontaner {
  width: 100%;
  height: 30px;
  // background: greenyellow;
  display: flex;
  align-items: center;
  background: var(--themesBg);
}
</style>>
