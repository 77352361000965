// 路由菜单信息

//图标集合
let mrouterIcon = ["DashboardOutlined", "TeamOutlined"];
let mrouter = [{
    "menuId": 1,
    "name": "概况",
    "router": "webIndex",
    "path": "/home",
    "icon": "DashboardOutlined",
}, {
    "menuId": 2,
    "name": "经销商",
    "router": "",
    "icon": "TeamOutlined",
    "children": [
        {
            "menuId": 3,
            "name": "申请列表",
            "router": "applicationList",
            "path": "/applicationList",
            "icon": "",
            "children": "",
        },
        {
            "menuId": 4,
            "name": "经销商列表",
            "router": "dealerList",
            "path": "/dealerList",
            "icon": "",
            "children": [
                {
                    "menuId": 10,
                    "name": "新建经销商",
                    "router": "addDealerList",
                    "path": "/dealerList/addDealerList",
                    "icon": "",
                    "children": "",
                },
                {
                    "menuId": 11,
                    "name": "经销商详情",
                    "router": "detailDealerList",
                    "path": "/dealerList/detailDealerList",
                    "icon": "",
                    "children": "",
                },
                {
                    "menuId": 12,
                    "name": "编辑经销商",
                    "router": "editDealerList",
                    "path": "/dealerList/editDealerList",
                    "icon": "",
                    "children": "",
                },
            ],
        },
        {
            "menuId": 5,
            "name": "续费记录",
            "router": "renewalRecord",
            "path": "/renewalRecord",
            "icon": "",
            "children": "",
        },
    ]
}, {
    "menuId": 6,
    "name": "产品分类",
    "router": "",
    "icon": "TeamOutlined",
    "children": [
        {
            "menuId": 7,
            "name": "产品分类",
            "router": "productCategories",
            "path": "/productCategories",
            "icon": "",
            "children": "",
        },
        {
            "menuId": 8,
            "name": "产品列表",
            "router": "productList",
            "path": "/productList",
            "icon": "",
            "children": "",
        },
        {
            "menuId": 9,
            "name": "质保信息",
            "router": "warrantyInfo",
            "path": "/warrantyInfo",
            "icon": "",
            "children": [{
                "menuId": 13,
                "name": "新增质保信息",
                "router": "addwarrantyInfo",
                "path": "/warrantyInfo/addwarrantyInfo",
                "icon": "",
                "children": "",
            }, {
                "menuId": 14,
                "name": "编辑质保信息",
                "router": "editwarrantyInfo",
                "path": "/warrantyInfo/editwarrantyInfo",
                "icon": "",
                "children": "",
            }, {
                "menuId": 15,
                "name": "质保信息详情",
                "router": "detailwarrantyInfo",
                "path": "/warrantyInfo/detailwarrantyInfo",
                "icon": "",
                "children": "",
            },],
        },
    ]
}];

export {
    mrouterIcon,
    mrouter,
}