<template>
  <div class="m-container">
    <!-- <a-menu
      v-model:openKeys="openKeys"
      v-model:selectedKeys="selectedKeys"
      style="width: 160px"
      mode="inline"
      @click="handleClick"
      @openChange="onOpenChange"
    >
      <a-menu-item key="3">
        <template #icon>
          <dashboard-outlined />
          <DashboardOutlined />
        </template>
        <span>概况</span>
      </a-menu-item>
      <a-sub-menu key="sub2" @titleClick="titleClick">
        <template #icon>
          <TeamOutlined />

        </template>
        <template #title>经销商</template>
        <a-menu-item key="1">申请列表</a-menu-item>
        <a-menu-item key="2">经销商列表</a-menu-item>
        <a-menu-item key="3">续费记录</a-menu-item>
      </a-sub-menu>
      <a-sub-menu key="sub3">
        <template #icon>
          <TeamOutlined />
        </template>
        <template #title>产品分类</template>
        <a-menu-item key="4">产品分类</a-menu-item>
        <a-menu-item key="5">产品列表</a-menu-item>
        <a-menu-item key="6">质保信息</a-menu-item>
      </a-sub-menu>
    </a-menu> -->
    <a-menu
      v-model:openKeys="openKeys"
      v-model:selectedKeys="selectedKeys"
      style="width: 160px"
      mode="inline"
      @click="handleClick"
      @openChange="onOpenChange"
    >
      <template v-for="item in mRouterList" :key="item.menuId">
        <!-- 二级 -->
        <a-sub-menu
          v-if="item.children && item.children.length > 0"
          :key="item.menuId"
          @titleClick="titleClick"
        >
          <template #icon>
            <!-- <TeamOutlined /> -->
            <component :is="item.icon"></component>
          </template>
          <template #title>{{ item.name }}</template>
          <a-menu-item
            v-for="child in item.children"
            :key="child.menuId"
            @click.stop="pushRouter(child)"
            >{{ child.name }}</a-menu-item
          >
        </a-sub-menu>
        <!-- 单个一级 -->
        <a-menu-item
          v-else-if="item.router && item.path"
          :key="item.menuId"
          @click.stop="pushRouter(item)"
        >
          <template #icon>
            <!-- <DashboardOutlined /> -->
            <component :is="item.icon"></component>
          </template>
          <span>{{ item.name }}</span>
        </a-menu-item>
      </template>
    </a-menu>
  </div>
</template>
<script>
import { useRouter } from "vue-router";
import { defineComponent, onMounted, reactive, ref, toRefs, watch } from "vue";
import { mrouter } from "@/router/mrouter";
import { DashboardOutlined, TeamOutlined } from "@ant-design/icons-vue";
// import router from "@/router";
export default defineComponent({
  name: "iMenu",
  components: {
    DashboardOutlined,
    TeamOutlined,
  },
  props: {
    routerId: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  setup(props) {
    let aaaa = ref(null);
    const mainRouter = useRouter();
    let mRouterList = reactive(mrouter);
    let state = reactive({
      openKeys: [1], //展开项
      selectedKeys: [1], //选中项
    });
    watch(
      () => state.openKeys,
      (val) => {
        console.log("openKeys", val, aaaa.value);
      }
    );
    let setNum = 0;
    watch(props, (val) => {
      // 只执行初始化一次防止重复执行
      if (setNum > 0) {
        return;
      }
      setNum++;
      // console.log("接受到值的变化", val);
      val = JSON.parse(JSON.stringify(val));
      if (val && val.routerId && val.routerId.length > 0) {
        // state.openKeys = [val.routerId[0]];
        // state.selectedKeys = [val.routerId[1]];
        handleClick({ keyPath: val.routerId });
      }
    });
    onMounted(() => {
      // 重新给菜单赋值
    });
    const titleClick = (e) => {
      console.log("单据某项标题点击", e);
    };
    const handleClick = (e) => {
      console.log("关闭其余的", e, e.keyPath);
      if (e.keyPath && e.keyPath.length == 1) {
        state.openKeys = [e.keyPath[0]];
        state.selectedKeys = [e.keyPath[0]];
      } else if (e.keyPath && e.keyPath.length > 0) {
        state.openKeys = [e.keyPath[0]];
        state.selectedKeys = [e.keyPath[1]];
      }
    };

    const onOpenChange = (openKeys) => {
      // const latestOpenKey = openKeys.find(
      //   (key) => state.openKeys.indexOf(key) === -1
      // );
      // if (state.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      //   state.openKeys = openKeys;
      // } else {
      //   state.openKeys = latestOpenKey ? [latestOpenKey] : [];
      // }
      console.log("改变的结果。。。。", openKeys, state);
    };
    let pushRouter = (result) => {
      if (result.path) {
        mainRouter.push(result.path);
      }
    };
    return {
      mRouterList,
      ...toRefs(state),
      onOpenChange,
      titleClick,
      handleClick,
      pushRouter,
    };
  },
});
</script>
<style lang="less" scoped>
.m-container {
  width: 160px;
  height: calc(100vh - 56px);
  padding-top: 29px;
  // background: rebeccapurple;
  background: var(--themesBg);
}

:deep(.ant-menu) {
  background: var(--themesBg) !important;
}
</style>>
